import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import Alert from '../../components/Alert/Alert'
import SEO from '../../components/seo'
import LinkList from '../../components/LinkList/LinkList'

const EventsPage = () => {
  const intl = useIntl()

  return (
    <Layout
      header={
        <span className='bold'>
          <FormattedMessage id='nav.events' />
        </span>
      }
      breadcrumb={{
        text: <FormattedMessage id='nav.news' />,
        path: '/news-and-events/'
      }}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.events' })}
      />
      <Container>
        {intl.locale === 'fr' && (
          <Row align='stretch'>
            <Col>
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <h2>Upcoming Events</h2>
            {/* <Alert>
              <p style={{ marginTop: `0` }}>
                <i>New events will be posted when available.</i>
              </p>
            </Alert> */}
            <p>
              The Home Construction Regulatory Authority (HCRA) will be hosting
              its 2024 Annual Meeting to provide stakeholders and members of the
              public with updates and reports on activities over the past year.
              The Annual Meeting will be held in a hybrid format with the
              opportunity for participants to take part in a live
              question-and-answer session.
            </p>
            <p>
              To register for the HCRA's Annual Meeting, use the link below.
            </p>
            <LinkList
              links={[
                {
                  path: 'https://livecast.live/hcra-agm-2024-registration/',
                  text: "HCRA's 2024 Annual Meeting",
                  subtitle: 'September 12, 2024'
                }
              ]}
            />
          </Col>
        </Row>

        <Row class='pageRow'>
          <Col>
            <h2>Past Events</h2>
            <LinkList
              links={[
                {
                  path: 'https://www.youtube.com/watch?v=YwOYhccDJdA',
                  text: 'Keys to Comfort: Info for New Homebuyers and Owners',
                  subtitle: 'June 2024'
                },
                {
                  path: 'https://www.youtube.com/watch?v=saKVw6eaKJg',
                  text: 'Understanding the Code of Ethics Webinar',
                  subtitle: 'August 2021'
                },
                {
                  path: 'https://www.youtube.com/watch?v=zfUgNxLyMSM',
                  text: 'Introduction to the HCRA',
                  subtitle: 'January 2021'
                }
              ]}
            />
          </Col>
        </Row>

        <Row class='pageRow'>
          <Col>
            <h2>Annual Meeting</h2>
            <p>
              You can view previous webcasts of the HCRA’s Annual Meeting below.
            </p>
            <LinkList
              links={[
                {
                  path: 'https://youtu.be/hd8AhWDA-2M',
                  text: '2023 Annual Meeting',
                  subtitle: 'September 2023'
                },
                {
                  path: 'https://youtu.be/prMAG98GcjQ',
                  text: '2022 Annual Meeting',
                  subtitle: 'September 2022'
                },
                {
                  path: 'https://www.youtube.com/watch?v=kNbh1SarcPo&t=7s',
                  text: '2021 Annual Meeting',
                  subtitle: 'September 2021'
                }
              ]}
            />
            <p>
              In addition to the questions answered live during the Annual
              Meeting, the HCRA addressed and responded to all written questions
              submitted by attendees. Please see general questions and their
              answers available to the public.
            </p>
            <LinkList
              links={[
                {
                  path: '../../../Annual Meeting 2023 Q&A.pdf',
                  text: '2023 Question and Answers',
                  subtitle: 'September 2023'
                },
                {
                  path: '../../../Annual Meeting 2022 Post Event Q&A.pdf',
                  text: '2022 Questions and Answers',
                  subtitle: 'September 2022'
                }
              ]}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <h2>
              <FormattedMessage id='events.headerA' />
            </h2>
            <Alert>
              <p style={{ marginTop: `0` }}>
                <i>
                  <FormattedMessage id='events.placeholder' />
                </i>
              </p>
            </Alert>
            { <LinkList links={[
              {
                path: "https://www.eventbrite.ca/e/understanding-the-code-of-ethics-tickets-164372315019",
                text: "Understanding the Code of Ethics Webinar",
                subtitle: "September 10th, 2021 12:00-1:00PM"              
              }
            ]}/>}
          </Col>
        </Row>

        <Row class='pageRow'>
          <Col>
            <h2>
              <FormattedMessage id='events.headerB' />
            </h2>
            <LinkList
              links={[
                {
                  path: 'https://youtu.be/prMAG98GcjQ',
                  text: <FormattedMessage id='events.annualMeetingHeader' />,
                  subtitle: <FormattedMessage id='events.eventDii' />
                },
                {
                  path: '../../Annual Meeting 2022 Post Event Q&A.pdf',
                  text: '2022 Questions and Answers',
                  subtitle: <FormattedMessage id='events.eventDii' />
                },
                {
                  path: 'https://www.youtube.com/watch?v=kNbh1SarcPo&t=7s',
                  text: <FormattedMessage id='events.eventBi' />,
                  subtitle: <FormattedMessage id='events.eventBii' />
                },
                {
                  path: 'https://www.youtube.com/watch?v=saKVw6eaKJg',
                  text: <FormattedMessage id='events.eventCi' />,
                  subtitle: <FormattedMessage id='events.eventCii' />
                },
                {
                  path: 'https://www.youtube.com/watch?v=zfUgNxLyMSM',
                  text: <FormattedMessage id='events.eventAi' />,
                  subtitle: <FormattedMessage id='events.eventAii' />
                }
              ]}
            />
          </Col>
        </Row> */}
      </Container>
    </Layout>
  )
}
export default EventsPage
